const modules = import.meta.glob('../**/*.html', {as: 'raw', eager: true});
// module to hold partial cache in production mode
angular.module("metis.partials", []);

angular.module('metis.partials').run(['$templateCache', function ($templateCache) {
  $templateCache.put("/static/app/empty.html", "");
  // this is an equivalent of the html2js in bower config
  for (const path in modules) {
    const splits = path.split('./scripts');
    if (splits.length > 1){
      $templateCache.put(splits[1], modules[path]);
    }
  }
}]);
